const SimplyLink = (props) => {
	return (
		<input
			type="button"
			className="link"
			{...props}
		/>
	)
}

export default SimplyLink