import { text } from '@src/helpers/text'
import './Btn.scss'

const Btn = ({
	label = '',
	isSending,
	type = 'primary',
	...props
}) => {
	return (
		<button type="submit" className={`Btn Btn_${type}`} {...props}>
			{label || text.notSetCompact}
			{/* {isSending && <img src="/icons/spinner.gif" width={16} alt="Отправка"/>} */}
		</button>
	)
}

export default Btn