export const demoGallery = [
	{
		id: "0",
		photo: "example-1.jpg"
	},
	{
		id: "1",
		photo: "example-2.jpg"
	},
	{
		id: "2",
		photo: "example-3.jpg"
	},
	{
		id: "3",
		photo: "example-4.jpg"
	},
	{
		id: "4",
		photo: "example-1.jpg"
	},
	{
		id: "5",
		photo: "example-2.jpg"
	},
	{
		id: "6",
		photo: "example-4.jpg"
	},
	{
		id: "7",
		photo: "example-3.jpg"
	},
]