import { writeErrors } from "./writeErrors"

export const updateFormData = ({
	formData,
	name,
	value,
	setFormData,
	setErrors,
	validate = false
}) => {
	const newFormData = { ...formData, [name]: value }
	setFormData(newFormData)
	if (validate)
		writeErrors( validate(newFormData), setErrors )
}