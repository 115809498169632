import { getClientData } from '@src/utility/getClientData';
import { trimString } from '@src/utility/trimString';
import './ClientInfo.scss'

const ClientInfo = () => {
	const clientData = getClientData()

	return (
		<div className="ClientInfo">
			<div>{trimString(clientData?.name, 18)}</div>
			<div>{clientData?.phone_number}</div>
		</div>
	);
}

export default ClientInfo