import './NoConfigNote.scss'

const NoConfigNote = () => {
	return (
		<div className="note-screen">
			<div className="container">
				<article>
					<h1>Конфигурационный файл не найден</h1>
					<p>Настройка приложения не завершена. Необходимо создать файл <code>config.js</code> в корне проекта со следующем содержимым:</p>
					<p>
						<pre>
							{'var GlobalAppConfig = {\n'}
							{'	protocol: \'https\',\n'}
							{'	domain: \'myapi.com\',\n'}
							{'	port: \'8080\'\n'}
							{'	path: \'api\'\n'}
							{'}'}
						</pre>
					</p>
					<p>Где <code>domain</code> – адрес вашего API сервиса, <code>protocol</code> – протокол <i>http</i> или <i>https</i>, а <code>port</code> (опционально) и <code>path</code> (опционально) – дополнительные порт и путь, если нужно.</p>
					<hr />
					<p><b>Если вы являетесь обычным пользователем системы, обратитесь к администратору.</b></p>
					<br />
				</article>
			</div>
		</div>
	)
}

export default NoConfigNote