import './ActionBtn.scss'

const ActionBtn = ({text, ...props}) => {
	return (
		<button className="ActionBtn" {...props}>
			{text}
		</button>
	)
}

export default ActionBtn