import { useNavigate } from 'react-router-dom'
import BackBtn from '../BackBtn/BackBtn'
import './MegaHeader.scss'

const MegaHeader = ({ title, design = 'tools' }) => {
	const navigate = useNavigate()

	const backLinkAction = () => {
		navigate(-1)
	}

	return (
		<div className={`MegaHeader MegaHeader_${design}`}>
			<div className="MegaHeader__actions">
				<div className="container">
					<BackBtn title="Назад" callback={backLinkAction}/>
				</div>
			</div>
			<div className="container">
				<h1>{title}</h1>
			</div>
		</div>
	)
}

export default MegaHeader