// import TestSock from "@src/components/Studio/TestSock"
// import TestSock1 from "@src/components/Studio/TestSock1"
import TestSock2 from "@src/components/Studio/TestSock2"
import useTitle from "@src/hooks/useTitle"

const Test = () => {
	const title = useTitle('Test Page')
	return (
		<div className="container">
			<h1>{title}</h1>
			<TestSock2 />
		</div>
	)
}

export default Test