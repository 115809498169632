import { ANY, DEFAULT } from './helpers/constants'

export const GlobalAppConfig = window.GlobalAppConfig
export const studioWebSocketUrl = 'wss://nails.rusit-ballony.ru/ws/video/'
export const studioPictureWidth = 960/2
export const studioPictureHeight = 1152/2

export const minPassLength = 8
export const maxNameLength = 32
export const maxEmailLength = 254
export const maxPhoneNumberLength = 16

export const phonePattern = /^\+\d{8,15}$/

export const protocol = GlobalAppConfig?.protocol || 'https'
export const clearDomain = GlobalAppConfig?.domain || 'localhost'
export const port = GlobalAppConfig?.port || ''
export const domain = `${protocol}://${clearDomain}${port ? ':' + port : ''}`

export const apiPath = GlobalAppConfig?.path || ''

export const url = [domain, apiPath]

export const api = {
	createUser: 'users',
	createClient: 'clients/create',
	loginClient: 'clients/login',
	userInfo: 'users/me',
	login: 'auth/token/login',
	logout: 'auth/token/logout',
	editClient: 'clients/update',
	masters: 'masters',
	rateMaster: 'masters/rate',
	designs: 'designs',
}

export const path = {
	[DEFAULT]: '/',
	[ANY]: '*',

	HOME: '/',

	CLIENT_LOGIN: '/client-login',
	CLIENT_PROFILE: '/client-profile',
	CLIENT_REGISTRATION: '/client-registration',
	CLIENT_EDIT: '/client-edit',
	CREATION: '/creation',
	ENTERTAINMENT: '/entertainment',
	MASTER: '/master',
	RATES: '/rates',
	REFERENCES: '/references'
}

export const defaultNailRGBA = [170, 170, 170, 1]
export const defaultNailGlowRGBA = [255, 255, 255, 1]

export const galleryPageSize = 4