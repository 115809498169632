import useFetchData from '@src/hooks/useFetchData'
import { getAPI } from '@src/utility/getAPI'
import { api } from '@src/config'
import { IS_LOADED } from '@src/helpers/constants'

const useMasterDetail = (id) => {
	const {fetchData, status} = useFetchData(
		getAPI(api.masters)
	)

	if (status === IS_LOADED)
		return {
			result: fetchData.find(element => element.id == id),
			...{status}
		}
	else
		return {
			result: {},
			...{status}
		}

}

export default useMasterDetail