import { EMPTY_LIST } from '@src/helpers/constants'
import Message from '../Message/Message'
import './Gallery.scss'

const Gallery = ({ images }) => {

	return (
		<div className="Gallery">
			{images && images?.length > 0 ?
				images.map((image, index) => (
					<img
						key={index}
						src={image.photo}
						className="Gallery__item"
						alt=""
					/>
				))
				:
				<Message status={EMPTY_LIST} />
			}
		</div>
	)
}

export default Gallery
