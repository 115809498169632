import { PUT } from '@src/helpers/constants'
import { getPaymentAPI } from '@src/utility/getPaymentAPI'
import { getUserToken } from '@src/services/auth/getUserToken'
import { request } from '@src/utility/request'

export const createPayment = async (selectedRate, userId) => {

	const userToken = getUserToken()

	try {
		const {response} = await request({
			url: getPaymentAPI(userId),
			token: userToken,
			message: { plan: selectedRate },
			method: PUT,
		})

		console.debug({response})

		return response

	} catch (error) {
		console.error('Error sending request:', error)
	}

}
