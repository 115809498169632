import useFetchData from '@src/hooks/useFetchData'
import { getAPI } from '@src/utility/getAPI'
import { api } from '@src/config'

const useMastersList = () => {
	return useFetchData(
		getAPI(api.masters)
	)
}

export default useMastersList