import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Input from '@src/components/Input/Input'
import { maxNameLength, maxPhoneNumberLength, path } from '@src/config'
import LightFormModule from '@src/modules/LightForm/LightFormModule'
import Btn from '../Btn/Btn'
import { updateFormData } from '@src/utility/updateFormData'
import { haveErrors } from '@src/utility/haveErrors'
import { text } from '@src/helpers/text'
import { reloadPage } from '@src/utility/reloadPage'
import { validateClientRegistrationData } from '@src/utility/validation/validateClientRegistrationData'
import { createClient } from '@src/services/auth/createClient'
import { saveClientData } from '@src/utility/saveClientData'
import { clientIsLogged } from '@src/utility/auth/client/clientIsLogged'
import ErrorMessage from '@src/components/ErrorMessage/ErrorMessage'
import useTitle from '@src/hooks/useTitle'
import './ClientRegistrationForm.scss'

const ClientRegistrationForm = () => {
	const { CLIENT_LOGIN } = path
	const navigate = useNavigate()

	const isLogged = clientIsLogged()
	const title = useTitle('Регистрация клиента')

	useEffect(() => {
		if (isLogged) {
			navigate(path.HOME)
		}
	})

	const [isSending, setIsSending] = useState(false)
	const [errors, setErrors] = useState({})

	const writeErrors = (value) => {
		setErrors(init => ({...init, ...value}))
	}

	const [formData, setFormData] = useState({
		phone_number: '+79008007060'
	})

	const handleChange = ({target}) => {
		const { name, value } = target
		updateFormData({
			formData,
			name,
			value,
			setFormData,
		})
	}

	const reject = (note, answer = {}) => {
		console.debug('reject-submitting', {note}, {answer})
		writeErrors({ ...note, ...answer }, setErrors)
		setIsSending(false)
	}

	const onSubmit = async (event) => {
		event.preventDefault()
		setIsSending(true)

		const actualErrors = validateClientRegistrationData(formData, writeErrors)

		if (haveErrors(actualErrors)) {
			reject({...actualErrors})
			return
		}

		const { success, response } = await createClient(formData)

		if (!success) {
			reject({common: text?.inputDataError})
			return
		}

		saveClientData(response)
		reloadPage()
	}

	return (
		<LightFormModule {...{onSubmit}}>
			<header>
				<h1>{title}</h1>
				<ErrorMessage {...{errors}} />
			</header>
			<section>
				<Input
					label="Имя"
					type="text"
					name="name"
					onChange={handleChange}
					value={formData?.name || ''}
					maxLength={maxNameLength}
					errors={errors?.name || ''}
				/>
				<Input
					label="Номер телефона"
					type="text"
					name="phone_number"
					onChange={handleChange}
					value={formData?.phone_number || ''}
					maxLength={maxPhoneNumberLength}
					errors={errors?.phone_number || ''}
				/>
			</section>
			<footer>
				<Btn label="Регистрация" disabled={isSending} />
				<Link to={CLIENT_LOGIN}>Уже есть аккаунт? Войти</Link>
			</footer>
		</LightFormModule>
	)
}

export default ClientRegistrationForm
