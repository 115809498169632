import { Outlet } from 'react-router-dom'
import Layout from '@src/components/Layout/Layout'
import './UserAuthLayout.scss'

const UserAuthLayout = () => {
	return (
		<Layout id="UserAuthLayout" className="auth-form">
			<Outlet />
		</Layout>
	)
}

export default UserAuthLayout