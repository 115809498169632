import { useState } from 'react'
import { Link } from 'react-router-dom'
import SimpleFormModule from '@src/modules/SimpleFormModule/SimpleFormModule'
import StarsInput from '@src/components/StarsInput/StarsInput'
import Btn from '@src/components/Btn/Btn'
import { getClientData } from '@src/utility/getClientData'
import { path } from '@src/config'
import { reloadPage } from '@src/utility/reloadPage'
import { writeErrors } from '@src/utility/writeErrors'
import { rateMaster } from '@src/services/rateMaster'
import { text } from '@src/helpers/text'
import './MastetFeedForm.scss'
import ErrorMessage from '../ErrorMessage/ErrorMessage'

const MastetFeedForm = ({masterId}) => {

	const [starsRating, setStarsRating] = useState(0)
	const [isSending, setIsSending] = useState(false)
	const [errors, setErrors] = useState({})

	const {id: clientId} = getClientData()

	const onRatingChange = (newValue) => {
		setStarsRating(newValue)
		setFormData({
			score: newValue,
			client: clientId,
			master: masterId
		})
	}

	const [formData, setFormData] = useState({
		score: null,
		client: clientId,
		master: masterId
	})

	const reject = (note, answer = {}) => {
		console.debug('reject-submitting', {note}, {answer})
		writeErrors({ ...note, ...answer }, setErrors)
		setIsSending(false)
	}

	const onSubmit = async (event) => {
		event.preventDefault()
		if (!formData?.score) {
			alert('Оценка не выбрана')
			return
		}
		setIsSending(true)

		const { success, status } = await rateMaster(formData)

		if (!success) {
			if (status !== 200)
				reject({common: text?.formSubmitCommonError})
			else
				reject({common: text?.unnamedError})
			return
		}

		reloadPage()
	}

	return (
		<div className="MastetFeedForm">
			{!clientId ?
				<div>
					Необходимо <b><Link to={path.CLIENT_LOGIN}>авторизоваться</Link></b> чтобы оставить отзыв.
				</div>
				:
				<SimpleFormModule {...{onSubmit}}>
					<ErrorMessage {...{errors}} />
					<section>
						<StarsInput {...{onRatingChange}} />
					</section>
					{/* <textarea placeholder="Текст сообщения"></textarea> */}
					<footer className="SimpleFormModule__actions">
						<Btn
							label="Отправить"
							disabled={!starsRating || isSending}
						/>
					</footer>
				</SimpleFormModule>
			}
		</div>
	)
}

export default MastetFeedForm