import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { path } from '@src/config'
import Rates from '@src/pages/Rates/Rates'
import RatesLayout from '@src/layouts/RatesLayout/RatesLayout'
import UserAuthLayout from '@src/layouts/auth/UserAuthLayout/UserAuthLayout'
import UserLogin from '@src/pages/auth/user/Login/UserLogin'
// import UserRegistration from '@src/pages/auth/user/Registration/UserRegistration'

const PublicRouter = () => {
	const {
		ANY,
		DEFAULT,
		RATES,
		// REGISTRATION
	} = path

	return (
		<BrowserRouter>
			<Routes>
				<Route path={DEFAULT} element={<UserAuthLayout />}>
					<Route index element={<UserLogin />} />
					{/* <Route path={REGISTRATION} element={<UserRegistration />} /> */}
				</Route>

				<Route path={RATES} element={<RatesLayout />}>
					<Route index element={<Rates />} />
				</Route>

				<Route path={ANY} element={<Navigate to={DEFAULT} />} />
			</Routes>
		</BrowserRouter>
	)
}

export default PublicRouter
