import useTitle from '@src/hooks/useTitle.js'
import MegaHeader from '@src/components/MegaHeader/MegaHeader'
import Gallery from '@src/components/Gallery/Gallery'
import { demoGallery } from '@src/helpers/demoGallery'
import './References.scss'

const References = () => {
	const title = useTitle('Референсы')

	return (
		<div>
			<MegaHeader design='material' {...{title}} />
			<div className="container">
				<Gallery images={demoGallery} />
			</div>
		</div>
	)
}

export default References
