import './Stars.scss'

const Stars = ({count = 0, large = false}) => {
	const starsArray = Array(5).fill(false).map((_, index) => index < count);

	return (
		<div className={`Stars${large ? ' Stars__large' : ''}`}>
			{starsArray.map((isFull, index) => (
				<div
					key={index}
					className={`Star${!isFull ? ' Star_light' : ''}`}
				></div>
			))}
		</div>
	);
}

export default Stars