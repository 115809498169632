import getRGBA from "./getRGBA"

const getNailColor = (mainColor, glowColor) => {
	return (
		{
			backgroundColor: getRGBA(mainColor),
			backgroundImage: `radial-gradient(ellipse, ${getRGBA(glowColor)}, rgba(255, 255, 255, 0) 70%)`

		}
	)
}

export default getNailColor