import { Outlet } from 'react-router-dom'
import Header from '@src/components/Header/Header'
import Layout from '@src/components/Layout/Layout'
import './RatesLayout.scss'

const RatesLayout = () => {
	return (
		<Layout id="RatesLayout">
			<Header />
			<Outlet />
		</Layout>
	)
}

export default RatesLayout