import { api } from '@src/config'
import { POST } from '@src/helpers/constants'
import { getAPI } from '@src/utility/getAPI'

export const createUser = async (payload) => {
	console.debug('createUser', { payload })
	console.debug(getAPI(api.createUser))

	const response = await fetch(getAPI(api.createUser), {
		method: POST,
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(payload)
	})

	const data = await response.json()
	return { status: response.ok, data }
}
