import BackBtn from "../BackBtn/BackBtn"
import Btn from "../Btn/Btn"
import "./StudioControll.scss"

const StudioControll = ({ backLinkAction, isPhotoMode, setIsPhotoMode }) => {
	return (
		<div className="StudioControll">
			<div className="container">
				<BackBtn title="Назад" callback={backLinkAction}/>
				{!isPhotoMode ? 
					<Btn label="Сделать фото результата" type="light" onClick={setIsPhotoMode}/>
				: ''}
			</div>
		</div>
	)
}

export default StudioControll