import { useNavigate } from 'react-router-dom'
import BackBtn from '../BackBtn/BackBtn'
import './ClientMegaHeader.scss'
import ClientInfo from '../ClientInfo/ClientInfo'
import Btn from '../Btn/Btn'
import { path } from '@src/config'

const ClientMegaHeader = ({ title, design = 'tools' }) => {
	const navigate = useNavigate()

	const backLinkAction = () => {
		navigate(-1)
	}

	const goToClientEdit = () => {
		navigate(path.CLIENT_EDIT)
	}

	return (
		<div className={`ClientMegaHeader ClientMegaHeader_${design}`}>
			<div className="ClientMegaHeader__actions">
				<div className="container">
					<BackBtn title="Назад" callback={backLinkAction}/>
				</div>
			</div>
			<div className="container">
				<ClientInfo />
				<Btn
					type="glass-wide"
					label="Изменить данные"
					onClick={goToClientEdit}
				/>
			</div>
		</div>
	)
}

export default ClientMegaHeader