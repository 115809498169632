export const demoNailsColors = [
	{
		id: 'TEST1',
		main: [170, 170, 170, 1],
		glow: [255, 255, 255, 1],
		code: "10",
		name: "OPI"
	},
	{
		id: 'TEST2',
		main: [136, 46, 67, 1],
		glow: [183, 75, 100, 1],
		code: "84",
		name: "OPI"
	},
	{
		id: 'TEST3',
		main: [255, 218, 140, 1],
		glow: [255, 232, 183, 1],
		code: "62",
		name: "OPI"
	},
	{
		id: 'TEST4',
		main: [21, 175, 93, 1],
		glow: [112, 206, 156, 1],
		code: "74",
		name: "OPI"
	},
	{
		id: 'TEST5',
		main: [188, 79, 253, 1],
		glow: [214, 148, 254, 1],
		code: "58",
		name: "OPI"
	},
	{
		id: 'TEST6',
		main: [255, 154, 179, 1],
		glow: [255, 193, 208, 1],
		code: "32",
		name: "OPI"
	},
	{
		id: 'TEST10',
		main: [170, 170, 170, 1],
		glow: [255, 255, 255, 1],
		code: "10",
		name: "OPI"
	},
	{
		id: 'TEST20',
		main: [136, 46, 67, 1],
		glow: [183, 75, 100, 1],
		code: "84",
		name: "OPI"
	},
	{
		id: 'TEST30',
		main: [255, 218, 140, 1],
		glow: [255, 232, 183, 1],
		code: "62",
		name: "OPI"
	},
	{
		id: 'TEST40',
		main: [21, 175, 93, 1],
		glow: [112, 206, 156, 1],
		code: "74",
		name: "OPI"
	},
	{
		id: 'TEST40',
		main: [21, 175, 93, 1],
		glow: [112, 206, 156, 1],
		code: "74",
		name: "OPI"
	},
	{
		id: 'TEST40',
		main: [21, 175, 93, 1],
		glow: [112, 206, 156, 1],
		code: "74",
		name: "OPI"
	},
	{
		id: 'TEST40',
		main: [21, 175, 93, 1],
		glow: [112, 206, 156, 1],
		code: "74",
		name: "OPI"
	},
	{
		id: 'TEST40',
		main: [21, 175, 93, 1],
		glow: [112, 206, 156, 1],
		code: "74",
		name: "OPI"
	},
	{
		id: 'TEST40',
		main: [21, 175, 93, 1],
		glow: [112, 206, 156, 1],
		code: "74",
		name: "OPI"
	},
	{
		id: 'TEST5',
		main: [188, 79, 253, 1],
		glow: [214, 148, 254, 1],
		code: "58",
		name: "OPI"
	},
	{
		id: 'TEST60',
		main: [255, 154, 179, 1],
		glow: [255, 193, 208, 1],
		code: "32",
		name: "OPI"
	},
]
