import { IS_LOADED } from '@src/helpers/constants'
import { addHotRatIndex } from '@src/utility/addHotRatIndex'

export const useRatesList = () => { // TODO Placeholder

	const exampleRates = [
		{
			id: 0,
			name: 'пробная подписка с ограниченным функционалом, которая  действительна месяц, далее её нужно продлевать по полной стоимости',
			price: '15000',
			is_demo: true,
			duration: 1,
		},
		{
			id: 1,
			name: 'eдиноразовая полная стоимость подписки на месяц',
			price: '20000',
			is_demo: false,
			duration: 1,
		},
		{
			id: 2,
			name: 'можно оплатить 3 месяца сразу и получить скидку 10%',
			price: '54000',
			is_demo: false,
			duration: 3,
		},
		{
			id: 3,
			name: 'оплачивая подписку на пол года, скидка 20%',
			price: '96000',
			is_demo: false,
			duration: 6,
		},
		{
			id: 6,
			name: 'в этом варианте мы предоставляем самый большой процент скидки 50%',
			price: '120000',
			is_demo: false,
			duration: 12,
		},
	]

	const result = addHotRatIndex(exampleRates)

	return {
		data: result,
		status: IS_LOADED
	}
}