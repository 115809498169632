import { useEffect, useState } from 'react'
import { Link, useNavigate, } from 'react-router-dom'
import Btn from '@src/components/Btn/Btn'
import Input from '@src/components/Input/Input'
import LightFormModule from '@src/modules/LightForm/LightFormModule'
import { haveErrors } from '@src/utility/haveErrors'
import { path } from '@src/config'
import { reloadPage } from '@src/utility/reloadPage'
import { updateFormData } from '@src/utility/updateFormData'
import { saveClientData } from '@src/utility/saveClientData'
import { loginClient } from '@src/services/auth/loginClient'
import { validateClientLoginData } from '@src/utility/validation/validateClientLoginData'
import { clientIsLogged } from '@src/utility/auth/client/clientIsLogged'
import ErrorMessage from '@src/components/ErrorMessage/ErrorMessage'
import useTitle from '@src/hooks/useTitle'
import './ClientLoginForm.scss'

const ClientLoginForm = () => {
	const { CLIENT_REGISTRATION } = path
	const navigate = useNavigate()

	const isLogged = clientIsLogged()
	const title = useTitle('Авторизация клиента')

	useEffect(() => {
		if (isLogged) {
			navigate(path.HOME)
		}
	})

	const [isSending, setIsSending] = useState(false)
	const [errors, setErrors] = useState({})

	const writeErrors = (value) => {
		setErrors(init => ({...init, ...value}))
	}

	const [formData, setFormData] = useState({
		phone_number: '+79008007060'
	})

	const handleChange = ({target}) => {
		const { name, value } = target
		updateFormData({
			formData,
			name,
			value,
			setFormData,
		})
	}

	const reject = (note, answer = {}) => {
		console.debug('reject-submitting', {note}, {answer})
		writeErrors({ ...note, ...answer }, setErrors)
		setIsSending(false)
	}

	const onSubmit = async (event) => {
		event.preventDefault()

		const actualErrors = validateClientLoginData(formData, writeErrors)

		if (haveErrors(actualErrors)) {
			reject({...actualErrors})
			return
		}

		const { success, message, response } = await loginClient(formData)

		console.log({success});
		if (!success) {
			reject({common: message})
			return
		}

		saveClientData(response)
		reloadPage()
	}

	return (
		<LightFormModule {...{onSubmit}}>
			<header>
				<h1>{title}</h1>
				<ErrorMessage {...{errors}} />
			</header>
			<section>
				<Input
					label="Номер телефона"
					type="text"
					name="phone_number"
					onChange={handleChange}
					value={formData?.phone_number}
					autoComplete='on'
					errors={errors?.phone_number}
				/>
			</section>
			<footer>
				<Btn label="Войти" disabled={isSending} />
				<Link to={CLIENT_REGISTRATION}>Регистрация</Link>
			</footer>
		</LightFormModule>
	)
}

export default ClientLoginForm