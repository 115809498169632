/* eslint-disable no-sequences */
/* eslint-disable no-unused-expressions */
import { useEffect, useRef, useState } from 'react'
import Btn from '@src/components/Btn/Btn'
import BtnSet from '@src/components/BtnSet/BtnSet'
import { studioWebSocketUrl } from '@src/config'

function TestSock2() {
	const videoRef = useRef(null)
	const canvasRef = useRef(null)
	const outputCanvasRef = useRef(null)
	const [socket, setSocket] = useState(null)
	const [hue, setHue] = useState(0)
	const [shape, setShape] = useState('')
	const [frameId, setFrameId] = useState(0)
	const [frameIntervalId, setFrameIntervalId] = useState(null)
	const delay = 30

	useEffect(() => {
		const ws = new WebSocket(studioWebSocketUrl)
		ws.binaryType = 'arraybuffer'

		ws.onopen = () => {
			console.log("WebSocket connection established")
		}

		ws.onerror = (event) => {
			console.error("WebSocket error occurred:", event)
		}

		ws.onclose = (event) => {
			console.log("WebSocket connection closed:", event)
		}

		ws.onmessage = (event) => handleIncomingFrame(event)

		setSocket(ws)

		return () => {
			ws.close()
		}
	}, [])

	const startVideo = () => {
		console.log("Requesting access to webcam...")

		navigator.mediaDevices.getUserMedia({ video: true })
			.then(stream => {
				console.log("Access granted, streaming video...")
				videoRef.current.srcObject = stream
				videoRef.current.play()

				// Запуск отправки кадров
				const intervalId = setInterval(sendFrame, delay)
				setFrameIntervalId(intervalId)
			})
			.catch(err => {
				console.error("Error accessing webcam: ", err)
			})
	}
	
	const stopVideo = () => {

		// Остановка видео потока
		const stream = videoRef.current.srcObject
		if (stream) {
			const tracks = stream.getTracks()
			tracks.forEach(track => track.stop()) // Останавливаем все треки
		}

		videoRef.current.srcObject = null

		// Остановка отправки кадров
		if (frameIntervalId) {
			clearInterval(frameIntervalId)
			setFrameIntervalId(null)
		}

		// Закрытие WebSocket соединения
		if (socket) {
			socket.close()
			setSocket(null) // Обнуление состояния WebSocket
		}
	
		console.log("Video streaming stopped.")
	}

	const handleIncomingFrame = (event) => {
		const data = new Uint8Array(event.data)
		const metadataLength = new Uint32Array(data.buffer.slice(0, 4))[0]
		// const metadata = JSON.parse(new TextDecoder().decode(data.slice(4, 4 + metadataLength)))
		const imageData = data.slice(4 + metadataLength)

		const blob = new Blob([imageData], { type: 'image/jpeg' })
		const img = new Image()
		img.src = URL.createObjectURL(blob)
		img.onload = () => {
			const outputContext = outputCanvasRef.current.getContext('2d')
			outputContext.drawImage(img, 0, 0, outputCanvasRef.current.width, outputCanvasRef.current.height)
		}
	}

	const resizeAndDraw = () => {
		const video = videoRef.current
		const context = canvasRef.current.getContext('2d')

		const maxWidth = 1280
		const maxHeight = 720
		const aspectRatio = video.videoWidth / video.videoHeight
		let newWidth = video.videoWidth
		let newHeight = video.videoHeight

		if (video.videoWidth > maxWidth || video.videoHeight > maxHeight) {
			if (aspectRatio > 1) {
				newWidth = maxWidth
				newHeight = maxWidth / aspectRatio
			} else {
				newHeight = maxHeight
				newWidth = maxHeight * aspectRatio
			}
		}
		
		canvasRef.current.width = newWidth
		canvasRef.current.height = newHeight
		context.drawImage(video, 0, 0, newWidth, newHeight)
	}

	const hsvToRgb = (h, s, v) => {
		let r, g, b
		let i = Math.floor(h * 6)
		let f = h * 6 - i
		let p = v * (1 - s)
		let q = v * (1 - f * s)
		let t = v * (1 - (1 - f) * s)

		switch (i % 6) {
			case 0: r = v, g = t, b = p; break
			case 1: r = q, g = v, b = p; break
			case 2: r = p, g = v, b = t; break
			case 3: r = p, g = q, b = v; break
			case 4: r = t, g = p, b = v; break
			case 5: r = v, g = p, b = q; break
			default: r = 0, g = 0, b = 0
		}

		return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)]
	}

	const getBlobImage = () => {
		return new Promise((resolve) => {
			canvasRef.current.toBlob((blob) => {
				resolve(blob)
			}, 'image/jpeg')
		})
	}

	const sendFrame = async () => {
		if (videoRef.current.readyState === videoRef.current.HAVE_ENOUGH_DATA) {
			resizeAndDraw()
			const blob = await getBlobImage()
			const rgb = hsvToRgb(hue / 360, 1, 1)
			const timestamp = Date.now()
			const frameData = { rgb, shape, timestamp, frameId }
			console.log({frameData});

			if (socket.readyState === WebSocket.OPEN) {
				const metadataBuffer = new TextEncoder().encode(JSON.stringify(frameData))
				const metadataLengthBuffer = new Uint32Array([metadataBuffer.length])
				
				const imageBuffer = await blob.arrayBuffer()
				const payload = new Uint8Array(metadataLengthBuffer.byteLength + metadataBuffer.byteLength + imageBuffer.byteLength)

				payload.set(new Uint8Array(metadataLengthBuffer.buffer), 0)
				payload.set(new Uint8Array(metadataBuffer), metadataLengthBuffer.byteLength)
				payload.set(new Uint8Array(imageBuffer), metadataLengthBuffer.byteLength + metadataBuffer.byteLength)

				socket.send(payload)
				setFrameId(prevFrameId => prevFrameId + 1)
			} else {
				console.log("WebSocket not open:", socket.readyState)
			}
		} else {
			console.log("Video not ready")
		}
	}
	
	return (
		<div className="App">
			<div>
				<h3>Видео с камеры</h3>
				<video ref={videoRef} autoPlay></video>
			</div>
			<div>
				<h3>Раскадровка с камеры</h3>
				<canvas ref={canvasRef} width="640" height="480"></canvas>
			</div>
			<div>
				<h3>Картинка с сервера</h3>
				<canvas ref={outputCanvasRef} width="640" height="480"></canvas>
			</div>
			<BtnSet>
				<Btn type="light" label="Start" onClick={startVideo} />
				<Btn type="light" label="Stop" onClick={stopVideo} />
			</BtnSet>
			<div style={{ width: '100%', marginTop: '10px' }}>
				<input type="range" min="0" max="360" value={hue} onChange={(e) => setHue(e.target.value)} style={{ width: '100%' }} />
			</div>
			<label htmlFor="shapeSelect">Shape</label>
			<select id="shapeSelect" value={shape} onChange={(e) => setShape(e.target.value)}>
				<option value=""></option>
				<option value="square">Square</option>
				<option value="stiletto">Stiletto</option>
				<option value="almond">Almond</option>
			</select>
		</div>
	)

}

export default TestSock2
