import './LightFormModule.scss'

const LightFormModule = ({children, onSubmit }) => {
	return (
		<form className="LightFormModule" {...{onSubmit}}>
			{children}
		</form>
	)
}

export default LightFormModule