import { useRef, useState } from 'react'
import RatesList from '@src/components/RatesList/RatesList'
import { useRatesList } from '@src/hooks/useRatesList'
import { IS_LOADED } from '@src/helpers/constants'
import Btn from '@src/components/Btn/Btn.js'
import BtnSet from '@src/components/BtnSet/BtnSet.js'
import LoginForm from '@src/components/LoginForm/LoginForm.js'
import Message from '@src/components/Message/Message.js'
import UserRegistrationForm from '@src/components/UserRegistrationForm/UserRegistrationForm.js'
import SimplyLink from '@src/components/SimplyLink/SimplyLink.js'
import useTitle from '@src/hooks/useTitle.js'
import { logoutUser } from '@src/services/auth/logoutUser.js'
import { reloadPage } from '@src/utility/reloadPage.js'
import { scrollTo } from '@src/utility/scrollTo.js'
import { submitRatesForm } from '@src/services/submitRatesForm.js'
import { userIsLogged } from '@src/services/auth/userIsLogged.js'
import { validateLoginData } from '@src/utility/validation/validateLoginData.js'
import { validateRatesData } from '@src/utility/validation/validateRatesData.js'
import { validateRegistrationData } from '@src/utility/validation/validateRegistrationData.js'
import { updateFormData } from '@src/utility/updateFormData'
import { writeErrors } from '@src/utility/writeErrors'
import UserInfo from '@src/components/UserInfo/UserInfo'
import './Rates.scss'


const Rates = () => {
	useTitle('Тарифы')

	const { data, status } = useRatesList()

	const [isLogged, setIsLogged] = useState(userIsLogged())

	const [errors, setErrors] = useState({})
	const [isSending, setIsSending] = useState(false)
	const [switchToLoginForm, setSwitchToLoginForm] = useState(false)
	const [realTimeValidate, setRealTimeValidate] = useState(false)

	const [formData, setFormData] = useState({
		name: '',
		email: '',
		password: '',
		confirmPassword: '',
		selectedRate: ''
	})

	const ratesRef = useRef(null)

	const scrollToForm = () => scrollTo(ratesRef)
	const clearErrors = () => setErrors({})

	const logout = async () => {
		await logoutUser()
		reloadPage()
	}

	const showLoginForm = () => {
		setSwitchToLoginForm(true)
		clearErrors()
	}

	const showRegistrationForm = () => {
		setSwitchToLoginForm(false)
		clearErrors()
	}

	const reject = (note, answer = {}) => {
		console.error('reject-submitting', {note}, {answer})
		writeErrors({ ...note, ...answer }, setErrors)
		setRealTimeValidate(true)
		setIsSending(false)
		scrollToForm()
	}

	const validate = (data) => {
		console.log('validate', {data})
		let newErrors = validateRatesData(data)

		if (!isLogged) {
			if (switchToLoginForm)
				newErrors = {...newErrors, ...validateLoginData(data)}
			else
				newErrors = {...newErrors, ...validateRegistrationData(data)}
		}
		return newErrors
	}

	const handleFormChange = ({target}) => {
		const { name, value } = target
		writeErrors( {common: null}, setErrors )

		updateFormData({
			formData,
			name,
			value,
			setFormData,
			setErrors,
			validate: realTimeValidate ? validate : false
		})
	}

	const onSubmit = async (event) => {
		submitRatesForm({...{
			event,
			reject,
			validate,
			formData,
			switchToLoginForm,
			setIsSending,
			isLogged,
			setIsLogged,
			setErrors,
			scrollToForm
		}})
	}

	let className = 'Rates'

	if (isLogged)
		className += ' Rates_ink'

	return (
		<div ref={ratesRef} {...{className}}>
			<div className="container">
				{errors?.common && errors?.common?.length ? <div className="error">{errors.common.join("\n")}</div> : ''}
				{!isLogged && (
					switchToLoginForm ?
						<div>
							<LoginForm
								onChange={handleFormChange}
								disabled={isSending}
								{...{formData, onSubmit, isSending, errors}}
							/>
							<div>
								<span>Нет аккаунта? </span>
								<SimplyLink
									value={'Зарегистрироваться'}
									onClick={showRegistrationForm}
								/>
							</div>
						</div>
						:
						<div>
							<UserRegistrationForm
								onChange={handleFormChange}
								disabled={isSending}
								{...{formData, onSubmit, isSending, errors}}
							/>
							<div>
								<span>Уже есть аккаунт? </span>
								<SimplyLink
									value={'Авторизоваться'}
									onClick={showLoginForm}
								/>
							</div>
						</div>
				)}

				<div>
					<header className="section-header">
						{isLogged ?
							<>
								<h2>Продлить тариф / выбрать новый</h2>
								<UserInfo />
								<SimplyLink
									value={'Выйти'}
									onClick={logout}
								/>
							</>
							:
							<h2>Выберите тариф</h2>
						}
						{errors.selectedRate && <div className="error">{errors.selectedRate}</div>}
					</header>

					{status === IS_LOADED ? (
						<RatesList
							data={data}
							formData={formData}
							onChange={handleFormChange}
							disabled={isSending}
						/>
					) : (
						<Message status={status} />
					)}
				</div>

				<BtnSet>
					<Btn label="Оплатить" onClick={onSubmit} disabled={isSending} />
				</BtnSet>
			</div>

		</div>
	)
}

export default Rates
