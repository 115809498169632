const parseParams = (list, result) => {

	for (const key in list) {
		if (key === 'params')
			parseParams(list[key], result)
		else if (Object.prototype.hasOwnProperty.call(list, key)) {
			result.push(`${encodeURIComponent(key)}=${encodeURIComponent(list[key])}`)
		}
	}

	return result
}

export const getUrlWithParams = (url, params) => {

	const queryParams = []
	parseParams(params, queryParams)

	const queryString = queryParams.join('&')
	if (queryString) {
		return `${url}?${queryString}`
	} else {
		return url
	}
}