import { maxNameLength, minPassLength } from '@src/config'

export const validateRegistrationData = (data) => {
	const errors = {}

	if (!data.name)
		errors.name = ['Необходимо указать название салона']
	else if (data.name.length > maxNameLength)
		errors.name = [`Максимальная длина названия: ${maxNameLength}`]
	else
		errors.name = null

	if (!data.email)
		errors.email = ['Необходимо указать Email']
	else if (!/\S+@\S+\.\S+/.test(data.email))
		errors.email = ['Email некорректен']
	else
		errors.email = null

	if (!data.password)
		errors.password = ['Пароль обязателен']
	else if (data.password.length < minPassLength)
		errors.password = [`Минимальная длина пароля: ${minPassLength}`]
	else
		errors.password = null

	if (data.password !== data.confirmPassword)
		errors.confirmPassword = ['Пароли не совпадают']
	else
		errors.confirmPassword = null

	return errors
}