import useUserData from "@src/hooks/useUserData"
import { IS_LOADED } from "@src/helpers/constants"

const UserInfo = () => {
	const {fetchData, status} = useUserData()

	return (
		<div>
			{status === IS_LOADED ?
				<>
					<div>
						Вы авторизованы как:
					</div>
					<div>
						{fetchData?.email}
					</div>
				</>
				: ''
			}
		</div>
	)
}

export default UserInfo