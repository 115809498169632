import './MegaGridModule.scss'

const MegaGridModule = ({children }) => {
	return (
		<div className="MegaGridModule">
			{children}
		</div>
	)
}

export default MegaGridModule