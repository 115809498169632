import { text } from '@src/helpers/text'
import { createUser } from '@src/services/auth/createUser'
import { loginUser } from '@src/services/auth/loginUser'
import { createPayment } from '@src/services/createPayment'
import { haveErrors } from '@src/utility/haveErrors'
import { writeErrors } from '@src/utility/writeErrors'

export const submitRatesForm = async ({
	event,
	reject,
	validate,
	formData,
	switchToLoginForm,
	setIsSending,
	isLogged,
	setIsLogged,
	setErrors,
	scrollToForm
}) => {
	event.preventDefault()

	const errors = await writeErrors(validate(formData), setErrors)

	if (haveErrors(errors)) {
		console.log({errors})
		console.log(11);
		
		reject({common: [text.inputDataError]})
		return
	}

	setIsSending(true)

	let newUserId = ''

	try {

		if (!isLogged) {

			const payload = {
				email: formData?.email,
				password: formData?.password,
			}

			if (!switchToLoginForm) {

				payload.name = formData?.name

				const {
					status: createUserStatus,
					data: createUserData
				} = await createUser(payload)
				console.log({createUserData});

				newUserId = createUserData?.id

				if (!createUserStatus || !newUserId) {
					console.log(22);

					reject({ common: [text.inputDataError], ...createUserData })
					setIsSending(false)
					scrollToForm()
					return
				}

			}

			const {
				status: loginUserStatus,
				data: loginUserData
			} = await loginUser(formData.email, formData.password)

			if (!loginUserStatus) {
				console.log(33);
				
				reject({common: [text.authError]}, ...loginUserData)
				return
			}

		}

		setIsLogged(true)
console.log({newUserId});

		const {
			status: paymentStatus,
			data: paymentData
		} = await createPayment(formData?.selectedRate, newUserId)

		if (!paymentStatus) {
			console.log(44);
			
			reject({selectedRate: text.paymentCreateError}, ...paymentData)
			return
		} else {
			alert('Успешный успех.')
		}

	} catch (error) {
		console.error(text.formSubmitCommonError, error)
		console.log(55);
		
		reject({common: [text.formSubmitCommonError]})
	}

}