import { Link } from 'react-router-dom'
import { text } from '@src/helpers/text'
import './Logo.scss'

const Logo = ({light = false, compact = false}) => {
	return (
		<Link to="/">
			<img
				src={`/logo${light ? '-light' : ''}.svg`}
				alt={text.siteName}
				className={`logo ${compact ? 'logo_compact' : ''}`}
			/>
		</Link>
	)
}

export default Logo