import { getUserToken } from '@src/services/auth/getUserToken'
import { request } from '@src/utility/request'
import { api } from '@src/config'
import { getAPI } from '@src/utility/getAPI'
import { POST } from '@src/helpers/constants'

export const createClient = async ({name, phone_number}) => {

	const userToken = getUserToken()

	try {
		const {response} = await request({
			url: getAPI([api.createClient]),
			token: userToken,
			message: {
				name,
				phone_number
			},
			method: POST,
		})

		console.debug({response})

		if (response?.name == name && response?.phone_number)
			return { success: true, ...{response} }
		else
			return { success: false, ...{response} }

	} catch (error) {
		console.error('Error sending request:', error)
	}

}
