import Input from '@src/components/Input/Input'
import { maxEmailLength, maxNameLength, minPassLength } from '@src/config'
import './UserRegistrationForm.scss'

const UserRegistrationForm = ({ formData, errors, onChange, onSubmit, disabled }) => {
	return (
		<form className="UserRegistrationForm" onSubmit={onSubmit}>
			<Input
				label="Название салона"
				type="text"
				name="name"
				value={formData.name}
				maxLength={maxNameLength}
				errors={errors.name}
				autoComplete="organization-title"
				{...{onChange, disabled}}
			/>
			<Input
				label="Email"
				type="email"
				placeholder="example@mail.com"
				name="email"
				value={formData.email}
				maxLength={maxEmailLength}
				errors={errors.email}
				autoComplete="email"
				{...{onChange, disabled}}
			/>
			<fieldset>
				<Input
					label="Пароль"
					type="password"
					name="password"
					value={formData.password}
					minLength={minPassLength}
					errors={errors.password}
					autoComplete="new-password"
					{...{onChange, disabled}}
				/>
				<Input
					label="Повторить пароль"
					type="password"
					name="confirmPassword"
					value={formData.confirmPassword}
					minLength={minPassLength}
					errors={errors.confirmPassword}
					autoComplete="new-password"
					{...{onChange, disabled}}
				/>
			</fieldset>
		</form>
	)
}

export default UserRegistrationForm
