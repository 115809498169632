import useTitle from '@src/hooks/useTitle'
import UserLoginForm from '@src/components/UserLoginForm/UserLoginForm'
import './UserLogin.scss'

const UserLogin = () => {
	useTitle('Вход салона')

	return (
		<div className="UserLogin">
			<UserLoginForm />
		</div>
	)
}

export default UserLogin
