import { useEffect, useState } from 'react'
import useMasterDesigns from '@src/hooks/useMasterDesigns'
import Gallery from '@src/components/Gallery/Gallery'
import { IS_LOADED } from '@src/helpers/constants'
import Message from '@src/components/Message/Message'
import Paginate from '@src/components/Paginate/Paginate'
import { galleryPageSize } from '@src/config'
import { useDispatch } from 'react-redux'
import { setFilters } from '@src/store/reducers/filtersSlice'
import { updatePageParams } from '@src/utility/updatePageParams'
import './PortfolioGallery.scss'

const PortfolioGallery = ({ masterId, setPortfolioCount }) => {

	const dispatch = useDispatch()

	const [params, setParams] = useState({
		page: 1,
		...{masterId}
	})
	
	const {fetchData, status} = useMasterDesigns(params)

	const handlePageClick = ({ selected }) => {
		console.log({ selected })
		if (selected >= 0) {
			setParams(updatePageParams(params, selected + 1))
		} else {
			console.warn('Pagination error', {selected})
		}
	}

	useEffect(() => {
		dispatch(setFilters({
			page: 'PortfolioGallery',
			filters: params
		}))
	}, [params, dispatch])

	useEffect(() => {
		setPortfolioCount(fetchData?.count || 0)
	}, [fetchData, setPortfolioCount])

	return (
		<div className="PortfolioGallery">
			{status === IS_LOADED ?
				<>
					<Gallery images={fetchData?.results} />
					<Paginate
						onPageChange={handlePageClick}
						pageRangeDisplayed={galleryPageSize}
						count={fetchData?.count || 1}
						page={params?.page}
					/>
				</>
				:
				<Message status={status} />
			}
		</div>
	)
}

export default PortfolioGallery
