
import useTitle from '@src/hooks/useTitle.js'
import Tabber from '@src/components/Tabber/Tabber'
import { useState } from 'react'
import './ClientProfile.scss'
import ClientMegaHeader from '@src/components/ClientMegaHeader/ClientMegaHeader'
import Gallery from '@src/components/Gallery/Gallery'
import { demoGallery } from '@src/helpers/demoGallery'

const ClientProfile = () => {
	useTitle('Профиль клиента')
	const [ activeTab, setActiveTab ] = useState('previous')

	const tabs = {
		'previous': 'Предыдущий маникюр',
		'featured': 'Избранное',
	}

	const onTabSelect = (newTab) => {
		setActiveTab(newTab)
	}

	return (
		<div className="ClientProfile">
			<ClientMegaHeader />
			<div className="container">
				<Tabber {...{tabs, onTabSelect}} />
				{activeTab === 'previous'
					&& <Gallery images={demoGallery} />
				}
				{activeTab === 'featured'
					&& <Gallery images={demoGallery} />
				}
			</div>
		</div>
	)
}

export default ClientProfile
