export const writeErrors = (value, setter) => {
	console.log('writeErrors', { value })

	return new Promise((resolve) => {
		setter((init) => {
			const updatedErrors = { ...init }

			for (const key in value) {
				if (value[key] === null) {
				delete updatedErrors[key]
				} else {
				updatedErrors[key] = value[key]
				}
			}

			resolve(updatedErrors)
			return updatedErrors
		})
	})
}
