import { useState, useEffect } from 'react'
import {
	FAILED,
	GET,
	IS_LOADED,
	LOADING
} from '@src/helpers/constants'
import { getUserToken } from '@src/services/auth/getUserToken'
import { getUrlWithParams } from '@src/utility/getUrlWithParams'
import { useDebounce } from '@src/hooks/useDebounce'
import { request } from '@src/utility/request'

const useFetchData = (rawUrl, params = {}) => {
	// console.debug('useFetchData', {params});
	
	const [fetchData, setData] = useState([])
	const [status, setStatus] = useState(LOADING)
	const [code, setCode] = useState()
	const [update, setUpdate] = useState(0)

	const token = getUserToken()
	const finaleParams = { ...params }
	const url = getUrlWithParams(rawUrl, finaleParams)
	const debounceUrl = useDebounce(url, 350)

	const updateResult = (status, current, data) => {
		if (status) setStatus(status)
		if (data) setData(data)
	}

	const fetchDataFromApi = async () => {
		updateResult(LOADING, url, {})
		try {
			const {success, response, status} = await request({ url, token, GET })
			setCode(status)
			if (success)
				updateResult(IS_LOADED, url, response)
			else
				updateResult(FAILED, url, response)
		} catch (error) {
			console.error({error})
			updateResult(FAILED)
		}
	}

	useEffect(() => {
		fetchDataFromApi()
		// console.debug({params});
		
	}, [url, token, update, debounceUrl])

	const refetchData = () => {
		setUpdate(update + 1)
	}

	return { fetchData, status, refetchData, code }
}

export default useFetchData
