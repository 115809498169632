import Rate from '@src/components/Rate/Rate'
import Message from '@src/components/Message/Message'
import { EMPTY_LIST } from '@src/helpers/constants'
import './RatesList.scss'

const RatesList = ({ data, formData, onChange, disabled }) => {
	if (!data || data.length === 0) return <Message status={EMPTY_LIST} />

	const selectedRate = formData?.selectedRate || null

	return (
		<div className="RatesList">
			{data.map((item, index) => (
				<Rate
					data={item}
					isSelected={item?.id.toString() === selectedRate}
					onSelect={() => onChange(
						{
							target: {
								name: 'selectedRate',
								value: item?.id.toString()
							}
						}
					)}
					key={`rate-${index}`}
					{...{disabled}}
				/>
			))}
		</div>
	)
}

export default RatesList
