export const haveErrors = (list) => {
	console.debug({list})
	// return !!Object.keys(list).length
	// return Object.keys(list).some(key => list[key] !== '')

	return Object.values(list).some(value => {
		if (Array.isArray(value) && value.length === 0)
			return false
		else
			return value !== null && value !== undefined && value !== ''
	})
}