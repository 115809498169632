import getRGBA from "./getRGBA";

const getContrastingColor = (value) => {
	// Извлекаем значения R, G, B из строки "rgba(r, g, b, a)"
	const rgbaValues = getRGBA(value).replace(/[^\d,]/g, '').split(',');
 
	// Преобразуем строковые значения в числа
	const r = parseInt(rgbaValues[0], 10);
	const g = parseInt(rgbaValues[1], 10);
	const b = parseInt(rgbaValues[2], 10);
 
	// Рассчитываем яркость по формуле
	const brightness = (r * 299 + g * 587 + b * 114) / 1000;
 
	// Возвращаем черный или белый в зависимости от яркости
	return brightness > 150 ? '#000000' : '#FFFFFF';
}

export default getContrastingColor