import { useParams } from 'react-router-dom'
import { useState } from 'react'
import useTitle from '@src/hooks/useTitle'
import MasterDetailHeader from '@src/components/MasterDetailHeader/MasterDetailHeader'
import Tabber from '@src/components/Tabber/Tabber'
import MastetFeedForm from '@src/components/MastetFeedForm/MastetFeedForm'
import useMasterDetail from '@src/hooks/useMasterDetail'
import PortfolioGallery from '@src/components/PortfolioGallery/PortfolioGallery'
import { getPortfolioLabel } from '@src/utility/getPortfolioLabel'
import './MasterDetail.scss'

const MasterDetail = () => {
	useTitle('Мой мастер')

	const {master} = useParams()

	const [portfolioCount, setPortfolioCount] = useState(0)
	const [activeTab, setActiveTab] = useState('portfolio')

	const {result: masterData} = useMasterDetail(master)

	const tabs = {
		'portfolio': getPortfolioLabel(portfolioCount),
		'feedback': 'Оставить отзыв',
	}

	const onTabSelect = (newTab) => {
		setActiveTab(newTab)
	}

	return (
		<div className="MasterDetail">
			<MasterDetailHeader {...masterData} />
			<div className="container">
				<Tabber {...{tabs, onTabSelect}} />
				{activeTab === 'portfolio'
					&& <PortfolioGallery
							masterId={master}
							{...{setPortfolioCount}}
						/>}
				{activeTab === 'feedback'
					&& <MastetFeedForm
							masterId={master}
						/>}
			</div>
		</div>
	)
}

export default MasterDetail
