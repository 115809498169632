import getContrastingColor from "@src/utility/getContrastingColor"
import getNailColor from "@src/utility/getNailColor"
import getRGBA from "@src/utility/getRGBA"
import { defaultNailGlowRGBA, defaultNailRGBA } from "@src/config"
import "./Nail.scss"

const Nail = ({
	nailId,
	mainColor = defaultNailRGBA,
	glowColor = defaultNailGlowRGBA,
	code,
	name,
	selectedId,
	setSelectedId
}) => {

	return (
		<div
			className={`Nail ${selectedId === nailId ? 'Nail_active' : ''}`}
			onClick={() => {setSelectedId(nailId)}}
		>
			<div
				className="Nail__preview"
				style={getNailColor(mainColor, glowColor)}
			></div>
			<div
				className="Nail__code"
				style={{
					backgroundColor: getRGBA(mainColor),
					color: getContrastingColor(mainColor)
				}}
			>
				{code}
			</div>
			<div className="Nail__name">{name}</div>
		</div>
	)
}
export default Nail