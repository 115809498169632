import { maxNameLength, maxPhoneNumberLength, phonePattern } from "@src/config";

export const validateClientRegistrationData = (data) => {
	const errors = {};
	console.log({data});

	if (!data?.name)
		errors.name = ['Имя обязательно'];

	if (data.name && data.name.length > maxNameLength)
		errors.name = [`Максимальная длина имени — ${maxNameLength} символа`];

	if (!data?.phone_number)
		errors.phone_number = ['Номер телефона обязателен'];

	if (data.phone_number) {
		if (data.phone_number.length > maxPhoneNumberLength) {
			errors.phone_number = errors.phone_number || [];
			errors.phone_number.push(`Максимальная длина номера телефона — ${maxPhoneNumberLength} символов`);
		}
		if (!phonePattern.test(data.phone_number)) {
			errors.phone_number = errors.phone_number || [];
			errors.phone_number.push('Некорректный формат номера телефона');
		}
	}

	return errors;
}