import './SimpleFormModule.scss'

const SimpleFormModule = ({children, onSubmit }) => {
	return (
		<form className="SimpleFormModule" {...{onSubmit}}>
			{children}
		</form>
	)
}

export default SimpleFormModule