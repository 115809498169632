import Logo from '../Logo/Logo'
import './CompactHeader.scss'

const CompactHeader = () => {
	return (
		<div className="CompactHeader">
			<Logo light compact />
		</div>
	)
}

export default CompactHeader