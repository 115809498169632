export const formatMonthsWithDeclension = (value) => {
	let remainder = value % 10
	let lastTwoDigits = value % 100

	let text = ''

	if (lastTwoDigits >= 11 && lastTwoDigits <= 19)
		text = value + ' месяцев'

	if (remainder === 1)
		text = value + ' месяц'

	else if (remainder >= 2 && remainder <= 4)
		text = value + ' месяца'

	else
		text = value + ' месяцев'

	return text
}
