
export const text = {
	siteName: 'Ronail',
	loading: 'Загрузка…',
	notFound: 'Ничего не найдено',
	unnamedError: 'Произошла ошибка. Попробуйте снова или обратитесь к администратору',
	empty: '—',
	emptyList: 'По данному запросу ничего не найдено',
	dataError: 'Ошибка данных',
	add: 'Добавить',
	delete: 'Удалить',
	yes: 'Да',
	no: 'Нет',
	noTime: 'Бессрочно',
	notSet: 'Не задано',
	notSetCompact: '…',
	authError: 'Ошибка авторизации',
	inputDataError: 'В введённых данных имеются ошибки',
	formSubmitCommonError: 'Ошибка отправки формы',
	paymentCreateError: 'Ошибка формирования платежа',
	clientNotFound: 'Пользователь не найден'
}