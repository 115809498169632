import { GET, PATCH, POST, PUT } from '../helpers/constants'

export const request = async ({
	url,
	token,
	message,
	method = GET,
	ifMultipart = false,
	isFormData = false
}) => {

	if (!url || !token) return

	const headers = {
		'Authorization': `Token ${token}`
	}

	if (!isFormData && ifMultipart) {
		headers['Accept'] = 'multipart/form-data'
		headers['Content-Type'] = 'multipart/form-data'
	} else if (!isFormData) {
		headers['Accept'] = 'application/json'
		headers['Content-Type'] = 'application/json'
	}
	
	const options = {
		method: method,
		headers: headers
	}

	if ((method === POST || method === PUT || method === PATCH) && message && !ifMultipart && !isFormData) {
		options.body = JSON.stringify(message)
	} else if (ifMultipart || isFormData) {
		options.body = message
	}

	try {
		const response = await fetch(url, options);
	
		if (!response.ok) {
			throw new Error(`Request failed with status ${response.status}`);
		}

		return {
			success: true,
			status: response.status,
			response: await response.json()
		};

	} catch (error) {
		let status = null;
		if (error.message.includes('Request failed with status')) {
			status = parseInt(error.message.split(' ').pop(), 10);
		}

		console.warn('An error occurred:', error);
		return { success: false, status, response: {} };
	}
}