import Logo from '@src/components/Logo/Logo'
import './Header.scss'

const Header = ({light}) => {
	return (
		<div id="Header">
			<div className="container">
				<Logo {...{light}} />
			</div>
		</div>
	)
}

export default Header