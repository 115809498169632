import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import SimpleFormModule from '@src/modules/SimpleFormModule/SimpleFormModule'
import Btn from '@src/components/Btn/Btn'
import Input from '../Input/Input'
import { updateFormData } from '@src/utility/updateFormData'
import { maxNameLength, maxPhoneNumberLength, path } from '@src/config'
import { getClientData } from '@src/utility/getClientData'
import { saveClientData } from '@src/utility/saveClientData'
import { haveErrors } from '@src/utility/haveErrors'
import { validateClientRegistrationData } from '@src/utility/validation/validateClientRegistrationData'
import { editClient } from '@src/services/auth/editClient'
import { text } from '@src/helpers/text'
import './ClientEditForm.scss'

const ClientEditForm = () => {

	const [isSending, setIsSending] = useState(false)
	const [errors, setErrors] = useState({})
	const [haveChanges, setHaveChanges] = useState(false)
	const navigate = useNavigate()

	const clientData = getClientData()

	const writeErrors = (value) => {
		setErrors(init => ({...init, ...value}))
	}

	const [formData, setFormData] = useState({
		name: clientData?.name,
		phone_number: clientData?.phone_number
	})

	const handleChange = ({target}) => {
		setHaveChanges(true)
		const { name, value } = target
		updateFormData({
			formData,
			name,
			value,
			setFormData,
		})
	}

	const reject = (note, answer = {}) => {
		console.debug('reject-submitting', {note}, {answer})
		writeErrors({ ...note, ...answer }, setErrors)
		setIsSending(false)
	}

	const onSubmit = async (event) => {
		event.preventDefault()
		setIsSending(true)

		const actualErrors = validateClientRegistrationData(formData, writeErrors)
		console.log({actualErrors});

		if (haveErrors(actualErrors)) {
			reject({...actualErrors})
			return
		}

		const { success, response } = await editClient(formData)

		if (!success) {
			reject({common: text?.inputDataError})
			return
		}

		saveClientData(response)
		navigate(path.CLIENT_PROFILE)
	}

	return (
		<SimpleFormModule {...{onSubmit}}>
			<section>
				<Input
					label="Имя"
					type="text"
					name="name"
					onChange={handleChange}
					value={formData?.name || ''}
					maxLength={maxNameLength}
					errors={errors?.name || ''}
				/>
				<Input
					label="Номер телефона"
					type="text"
					name="phone_number"
					onChange={handleChange}
					value={formData?.phone_number || ''}
					maxLength={maxPhoneNumberLength}
					errors={errors?.phone_number || ''}
				/>
			</section>
			<footer className="SimpleForm__actions">
				<Btn
					label="Отправить"
					disabled={isSending || !haveChanges}
				/>
			</footer>
		</SimpleFormModule>
	)
}

export default ClientEditForm