export const validateLoginData = (data) => {
	const errors = {}
	console.log('validateLoginData', !data.email)

	if (!data.email)
		errors.email = ['Необходимо указать Email']
	else
		errors.email = null

	if (!data.password)
		errors.password = ['Пароль обязателен']
	else
		errors.password = null

	console.log({errors})

	return errors
}