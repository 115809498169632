import './CardsList.scss'

const CardsList = ({ children }) => {
	return (
		<div className="CardsList">
			<div className="container">
				{children}
			</div>
		</div>
	)
}

export default CardsList