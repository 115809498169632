import useTitle from '@src/hooks/useTitle.js'
import './Entertainment.scss'
import MegaHeader from '@src/components/MegaHeader/MegaHeader'

const Entertainment = () => {
	const title = useTitle('Развлечения')

	return (
		<div>
			<MegaHeader design='material' {...{title}} />
			<div className="container">
				<br/>В разработке…
			</div>
		</div>
	)
}

export default Entertainment
