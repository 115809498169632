import { getUserToken } from '@src/services/auth/getUserToken'
import { request } from '@src/utility/request'
import { api } from '@src/config'
import { getAPI } from '@src/utility/getAPI'
import { POST } from '@src/helpers/constants'
import { text } from '@src/helpers/text'

export const loginClient = async ({phone_number}) => {

	const userToken = getUserToken()

	const {success, status, response} = await request({
		url: getAPI([api.loginClient]),
		token: userToken,
		message: {
			phone_number
		},
		method: POST,
	})

	if (status === 404)
		return {
			success: false,
			message: text.clientNotFound,
			...{response}
		}

	else if (success && response?.phone_number)
		return {
			success: true,
			message: '',
			...{response}
		}

	else
		return {
			success: false,
			message: text.unnamedError,
			...{response}
		}

}
