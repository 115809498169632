import ReactPaginate from 'react-paginate';
import './Paginate.scss'

const Paginate = (props) => {
	const { page = 1, count, pageRangeDisplayed } = props

	const pageCount = count ? Math.ceil(count / pageRangeDisplayed) : 1

	return (
		<ReactPaginate
			className="pagi"
			breakLabel="…"
			nextLabel=">"
			previousLabel="<"
			renderOnZeroPageCount={false}
			forcePage={page >= 1 ? page - 1 : 0}
			{...{pageCount}}
			{...props}
		/>
	)
}

export default Paginate