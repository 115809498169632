import GlobalRouter from './routers/GlobalRouter'
import NoConfigNote from './pages/NoConfigNote/NoConfigNote'
import { validateGlobalConfig } from './utility/validateGlobalConfig'
import './globalStyles/App.scss'

const App = () => {

	const configIsValid = validateGlobalConfig()

	return (
		<div id="App">
			{configIsValid
				? <GlobalRouter />
				: <NoConfigNote />
			}
		</div>
	)

}

export default App
