import { useState } from 'react'
import './Tabber.scss'

const Tabber = ({ tabs, onTabSelect }) => {
	const [activeTab, setActiveTab] = useState(Object.keys(tabs)[0])

	const handleTabClick = (key) => {
		setActiveTab(key)
		onTabSelect(key)
	}
	
	return (
		<div className="Tabber">
			<div className="Tabber-tabs">
				{Object.keys(tabs).map((key) => (
					<button
						key={key}
						className={`Tabber-tab ${key === activeTab ? 'Tabber-tab_active' : ''}`}
						onClick={() => handleTabClick(key)}
					>
						{tabs[key]}
					</button>
				))}
			</div>
		</div>
	)
}

export default Tabber