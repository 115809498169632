import { useState } from 'react'
import NailsSelector from '../NailsSelector/NailsSelector'
import ActionBtn from './ActionBtn'
import './StudioConstructor.scss'

const StudioConstructor = ({ modeIsSelected, setModeIsSelected }) => {

	const [mode, setMode] = useState('')

	const selecteMode = (value) => {
		setMode(value)
		setModeIsSelected(true)
	}

	return (
		<div className="StudioConstructor">
			{modeIsSelected ?
				<NailsSelector {...{mode}} />
			:
				<div className="StudioConstructor_btns">
					<ActionBtn text="Форма"  onClick={() => selecteMode('form')} />
					<ActionBtn text="Дизайн" onClick={() => selecteMode('design')} />
					<ActionBtn text="Цвет"   onClick={() => selecteMode('color')} />
					<ActionBtn text="Длина"  onClick={() => selecteMode('length')} />
				</div>
			}
		</div>
	)
}

export default StudioConstructor