import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { path } from '@src/config'
import ClientAuthLayout from '@src/layouts/auth/ClientAuthLayout/ClientAuthLayout'
import ClientLogin from '@src/pages/auth/client/Login/ClientLogin'
import ClientRegistration from '@src/pages/auth/client/Registration/ClientRegistration'
import Creation from '@src/pages/Creation/Creation'
import Entertainment from '@src/pages/Entertainment/Entertainment'
import Home from '@src/pages/Home/Home'
import Master from '@src/pages/Master/Master'
import NoPage from '@src/pages/NoPage/NoPage'
import References from '@src/pages/References/References'
import ClearLayout from '@src/layouts/ClearLayout/ClearLayout'
import ClientProfile from '@src/pages/ClientProfile/ClientProfile'
import MasterDetail from '@src/pages/MasterDetail/MasterDetail'
import RatesLayout from '@src/layouts/RatesLayout/RatesLayout'
import Rates from '@src/pages/Rates/Rates'
import ClientEdit from '@src/pages/ClientEdit/ClientEdit'
import Test from '@src/pages/Test'

const PrivateRouter = () => {
	const {
		ANY,
		CLIENT_LOGIN,
		CLIENT_PROFILE,
		CREATION,
		DEFAULT,
		ENTERTAINMENT,
		MASTER,
		REFERENCES,
		CLIENT_REGISTRATION,
		CLIENT_EDIT,
		RATES
	} = path

	return (
		<BrowserRouter>
			<Routes>
				<Route path={DEFAULT} element={<ClearLayout />}>
					<Route index element={<Home />} />
				</Route>

				<Route path={CLIENT_LOGIN} element={<ClientAuthLayout />}>
					<Route index element={<ClientLogin />} />
				</Route>

				<Route path={CLIENT_REGISTRATION} element={<ClientAuthLayout />}>
					<Route index element={<ClientRegistration />} />
				</Route>

				<Route path={MASTER}>
					<Route index element={<Master />} />
					<Route path={':master'} element={<MasterDetail />} />
				</Route>

				<Route path={CLIENT_PROFILE} element={<ClientProfile />} />
				<Route path={CLIENT_EDIT} element={<ClientEdit />} />
				<Route path={CREATION} element={<Creation />} />
				<Route path={REFERENCES} element={<References />} />
				<Route path={ENTERTAINMENT} element={<Entertainment />} />

				<Route path={RATES} element={<RatesLayout />}>
					<Route index element={<Rates />} />
				</Route>
				<Route path={'test'} element={<Test />} />

				<Route path={ANY} element={<NoPage />} />
			</Routes>
		</BrowserRouter>
	)
}

export default PrivateRouter
