// import { api } from '@src/config'
// import { POST } from '@src/helpers/constants'
// import { text } from '@src/helpers/text'
// import { getAPI } from '@src/utility/getAPI'
import { clearUserToken } from '@src/services/auth/clearUserToken'
import { clearClientData } from '@src/utility/clearClientData'
// import { getUserToken } from '@src/services/auth/getUserToken'

export const logoutUser = async () => {
	// const token = getUserToken()

	// const response = await fetch(getAPI(api.logout), {
	// 	method: POST,
	// 	headers: {
	// 		'Content-Type': 'application/json',
	// 		'Authorization': `Bearer ${token}`
	// 	},
	// })

	clearClientData()
	clearUserToken()

	return ({status: 'ok'})

	// if (!response.ok)
	// 	console.warn(text.authError)

	// return { status: response.ok }
}
