import { createSlice } from '@reduxjs/toolkit'

export const filters = createSlice({
	name: 'filters',
	initialState: {
		value: {},
	},
	reducers: {
		setFilters: (state, action) => {
			const { page, filters } = action.payload
			state.value[page] = filters
			localStorage.setItem('filters', JSON.stringify(state.value))
		},
		unsetFilters: (state, action) => {
			console.debug({action})
			const page = action.payload
			if (page) {
				delete state.value[page]
			} else {
				state.value = {}
			}
			localStorage.setItem('filters', JSON.stringify(state.value))
		},
	},
})

export const { setFilters, unsetFilters } = filters.actions

export default filters.reducer