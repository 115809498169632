import { api } from '@src/config'
import { POST } from '@src/helpers/constants'
import { text } from '@src/helpers/text'
import { getAPI } from '@src/utility/getAPI'
import { saveUserId } from '@src/services/auth/saveUserId'
import { saveUserToken } from '@src/services/auth/saveUserToken'

export const loginUser = async (email, password) => {
	const response = await fetch(getAPI(api.login), {
		method: POST,
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({ email, password })
	})

	const data = await response.json()

	if (response.ok || data.auth_token) {
		saveUserToken(data.auth_token)
		saveUserId(data.id)
		console.debug('authorized', {data})
	} else {
		console.warn(text.authError, {response})
	}

	return { status: response.ok, data }
}
