import { useEffect, useState } from 'react'
import getMessageProps from '@src/utility/getMessageProps'
import './Message.scss'

const Message = ({status, text}) => {

	const [content, setContent] = useState(status)
	const [type, setType] = useState('')

	useEffect(() => {
		const [newContent, newType] = getMessageProps(status)
		setContent(newContent)
		setType(newType)
	}, [status])

	return (
		<div className={`mbox mbox_${type}`}>
			{text || content}
		</div>
	)
}

export default Message