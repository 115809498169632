import useTitle from '@src/hooks/useTitle.js'
import './ClientLogin.scss'
import ClientLoginForm from '@src/components/ClientLoginForm/ClientLoginForm'
// import Logo from '@src/components/Logo/Logo'

const ClientLogin = () => {
	useTitle('Вход клиента')

	return (
		<div>
			{/* <Logo /> */}
			<ClientLoginForm />
		</div>
	)
}

export default ClientLogin
