import { getUserToken } from '@src/services/auth/getUserToken'
import { request } from '@src/utility/request'
import { api } from '@src/config'
import { getAPI } from '@src/utility/getAPI'
import { PATCH } from '@src/helpers/constants'
import { getClientData } from '@src/utility/getClientData'

export const editClient = async ({name, phone_number}) => {
	console.log('editClient', {name, phone_number});

	const userToken = getUserToken()
	const clientData = getClientData()
	const clientId = clientData?.id

	if (!clientId) {
		alert('Некорректный id клиента.')
		return
	}

	try {
		const {response} = await request({
			url: getAPI([api.editClient, clientId]),
			token: userToken,
			message: {
				name,
				phone_number
			},
			method: PATCH,
		})

		if (response?.name === name && response?.phone_number === phone_number)
			return { success: true, ...{response} }
		else
			return { success: false, ...{response} }

	} catch (error) {
		console.error('Error sending request:', error)
	}

}
