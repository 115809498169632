import { text } from '@src/helpers/text'
import { Link } from 'react-router-dom'
import './MegaBtn.scss'

const MegaBtn = ({to = '', label, size = 1, image, onClick}) => {

	let className = 'MegaBtn'

	if (image)
		className += ` MegaBtn_${image}`

	if (size !== 1)
		className += ` MegaBtn_${size}`

	return (
		<Link
			className="MegaBtn"
			{...{className, to, onClick}}
		>
			<span>
				{label || text.notSetCompact}
			</span>
		</Link>
	)
}

export default MegaBtn