import useTitle from '@src/hooks/useTitle.js'
import './NoPage.scss'

const NoPage = () => {
	useTitle('Страница не найдена')

	return (
		<div>
			NoPage
		</div>
	)
}

export default NoPage
