
import useFetchData from '@src/hooks/useFetchData'
import { getAPI } from '@src/utility/getAPI'
import { api } from '@src/config'

const useUserData = () => {
	return useFetchData(
		getAPI(api.userInfo)
	)
}

export default useUserData