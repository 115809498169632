// import './ErrorMessage.scss'

const ErrorMessage = ({errors}) => {
	return (
		<>
			{errors && errors?.common && <div className="error">{errors?.common}</div>}
		</>
	);
}

export default ErrorMessage