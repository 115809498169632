import {
	DANGER,
	EMPTY_LIST,
	FAILED,
	INFO,
	IS_LOADED,
	LOADING,
	NOT_FOUND,
	WARN
} from '@src/helpers/constants'
import { text } from '@src/helpers/text'

const getMessageProps = (status) => {
	switch (status) {

		case LOADING:
		case IS_LOADED:
			return [text.loading, INFO.toLowerCase()]

		case NOT_FOUND:
			return [text.notFound, WARN.toLowerCase()]

		case FAILED:
			return [text.unnamedError, DANGER.toLowerCase()]

		case EMPTY_LIST:
			return [text.emptyList, WARN.toLowerCase()]

		default:
			return [status, DANGER.toLowerCase()]
	}
}

export default getMessageProps