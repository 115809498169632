import { CLIENT_DATA } from '@src/helpers/constants'

export const getClientData = () => {
	const data = localStorage.getItem(CLIENT_DATA)

	if (data)
		return JSON.parse(data)
	else
		return {}
}
