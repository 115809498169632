import { useNavigate } from 'react-router-dom'
import useTitle from '@src/hooks/useTitle.js'
import MegaHeader from '@src/components/MegaHeader/MegaHeader'
import MasterCard from '@src/components/MasterCard/MasterCard'
import CardsList from '@src/components/CardsList/CardsList'
// import { demoMasters } from '@src/helpers/demoMasters'
import { path } from '@src/config'
import { getUserId } from '@src/services/auth/getUserId'
import useMastersList from '@src/hooks/useMastersList'
import './Master.scss'
import { IS_LOADED } from '@src/helpers/constants'
import Message from '@src/components/Message/Message'

const Master = () => {
	const title = useTitle('Мой мастер')
	const navigate = useNavigate()

	const navigateToMaster = (master) => {
		navigate(`${path.MASTER}/${master}`)
	}

	const salonId = getUserId()
	const {fetchData, status} = useMastersList(salonId)

	return (
		<div className="Master">
			<MegaHeader {...{title}} />
			<CardsList>
				{status === IS_LOADED ?
					<>
						{!fetchData?.length && <>Список пуст</>}
						{fetchData.map((master, index) => (
							<MasterCard
								key={index}
								onClick={() => navigateToMaster(master.id)}
								{...master}
							/>
						))}
					</>
					:
					<Message status={status} />
				}
			</CardsList>
		</div>
	)
}

export default Master
