import Stars from '@src/components/Stars/Stars'
import './MasterCard.scss'

const MasterCard = ({
	profile_photo,
	name,
	rating,
	description,
	experience,
	onClick
}) => {
	return (
		<div className="MasterCard" {...{onClick}}>
			<picture className="MasterCard__avatar">
				<img src={profile_photo} alt=""/>
			</picture>
			<div className="MasterCard__main">
				<div className="MasterCard__header">
					<div className="MasterCard__name">
						{name}
					</div>
					<Stars count={rating} />
				</div>
				<div className="MasterCard__desc">
					<span>{description}</span>
					<span>Стаж работы {experience}</span>
				</div>
			</div>
		</div>
	)
}

export default MasterCard