import { useState } from 'react';
import './StarsInput.scss';

const StarsInput = ({
	initialCount = 0,
	onRatingChange
}) => {
	const [hoveredStars, setHoveredStars] = useState(0);
	const [selectedStars, setSelectedStars] = useState(initialCount);

	const handleMouseEnter = (index) => {
		setHoveredStars(index + 1);
	};

	const handleMouseLeave = () => {
		setHoveredStars(0);
	};

	const handleClick = (index) => {
		const newRating = index + 1;
		setSelectedStars(newRating);
		if (onRatingChange) {
			onRatingChange(newRating);
		}
	};

	return (
		<div className="StarsInput">
			{Array(5).fill(false).map((_, index) => (
				<div
					key={index}
					className={`Star${(hoveredStars || selectedStars) > index ? '' : ' Star_light'}`}
					onMouseEnter={() => handleMouseEnter(index)}
					onMouseLeave={handleMouseLeave}
					onClick={() => handleClick(index)}
				></div>
			))}
		</div>
	);
}

export default StarsInput;
