import "./BackBtn.scss"

const BackBtn = ({callback, props}) => {
	return (
		<button
			className="BackBtn"
			onClick={callback}
			{...props}
		/>
	)
}

export default BackBtn