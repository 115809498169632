
import useFetchData from '@src/hooks/useFetchData'
import { getAPI } from '@src/utility/getAPI'
import { api } from '@src/config'

const useMasterDesigns = ({masterId, page = 1}) => {
	return useFetchData(
		getAPI(
			[api.masters, masterId, api.designs],
			page
		),
		{masterId, page}
	)
}

export default useMasterDesigns