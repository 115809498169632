import MegaHeader from '@src/components/MegaHeader/MegaHeader';
import useTitle from '@src/hooks/useTitle';
import ClientEditForm from '@src/components/ClientEditForm/ClientEditForm';
import './ClientEdit.scss'

const ClientEdit = () => {
	const title = useTitle('Изменить данные')

	return (
		<div className="ClientEdit">
			<MegaHeader design='chair' {...{title}} />
			<div className="container">
				<div className="block">
					<ClientEditForm />
				</div>
			</div>
		</div>
	);
}

export default ClientEdit
