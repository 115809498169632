import { maxPhoneNumberLength, phonePattern } from "@src/config";

export const validateClientLoginData = (data) => {
	const errors = {};

	if (data.phone_number) {
		if (data.phone_number.length > maxPhoneNumberLength) {
			errors.phone_number = errors.phone_number || [];
			errors.phone_number.push(`Максимальная длина номера телефона — ${maxPhoneNumberLength} символов`);
		}
		if (!phonePattern.test(data.phone_number)) {
			errors.phone_number = errors.phone_number || [];
			errors.phone_number.push('Некорректный формат номера телефона');
		}
	}

	return errors;
}