import useTitle from '@src/hooks/useTitle.js'
import CompactHeader from '@src/components/CompactHeader/CompactHeader'
import Studio from '@src/components/Studio/Studio'
import './Creation.scss'

const Creation = () => {
	useTitle('Создать свой дизайн')

	return (
		<div className="Creation">
			<CompactHeader />
			<Studio />
		</div>
	)
}

export default Creation
