import { Outlet } from 'react-router-dom'
import Layout from '@src/components/Layout/Layout'
import './ClearLayout.scss'

const ClearLayout = () => {
	return (
		<Layout id="ClearLayout">
			<Outlet />
		</Layout>
	)
}

export default ClearLayout