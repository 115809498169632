import { text } from '@src/helpers/text'
import { formatNumberWithSpaces } from '@src/utility/formatNumberWithSpaces'
import Rub from '@src/components/Rub/Rub'

export const FormatPrice = ({value}) => {

	return (
		<span className="FormatPrice">
			{value && value > 0
				? <>{formatNumberWithSpaces(value)} <Rub /></>
				: text.notSetCompact
			}
		</span>
	)

}