import { useState } from 'react'
import { demoNailsColors } from '@src/helpers/demoNailsColors'
import Nail from '../Nail/Nail'
import { Swiper, SwiperSlide } from 'swiper/react';
import './NailsSelector.scss'
import 'swiper/css';

const NailsSelector = ({mode}) => {

	const [selectedId, setSelectedId] = useState('')
	const colors = demoNailsColors

	return (
		// <Swiper
		// 	spaceBetween={50}
		// 	slidesPerView={3}
		// 	onSlideChange={() => console.log('slide change')}
		// 	onSwiper={(swiper) => console.log(swiper)}
		// >
		// 	<SwiperSlide>Slide 1</SwiperSlide>
		// 	<SwiperSlide>Slide 2</SwiperSlide>
		// 	<SwiperSlide>Slide 3</SwiperSlide>
		// 	<SwiperSlide>Slide 4</SwiperSlide>
		// </Swiper>
		<div className="NailsSelector">
			<Swiper
				spaceBetween={10}
				slidesPerView={6}
				onSlideChange={() => console.log('slide change')}
				onSwiper={(swiper) => console.log(swiper)}
			>
				{colors.map((item, sectionIndex) => (
					<SwiperSlide>
						<Nail
							key={`${item?.id}-${sectionIndex}`}
							nailId={item?.id}
							mainColor={item?.main}
							glowColor={item?.glow}
							code={item?.code}
							name={item?.name}
							{...{selectedId, setSelectedId}}
							/>
					</SwiperSlide>
				))}
			</Swiper>
		</div>
	)
}

export default NailsSelector