import { useNavigate } from 'react-router-dom'
import useTitle from '@src/hooks/useTitle.js'
import ClientRegistrationForm from '@src/components/ClientRegistrationForm/ClientRegistrationForm'
import { path } from '@src/config'
import { clientIsLogged } from '@src/utility/auth/client/clientIsLogged'
import './ClientRegistration.scss'

const ClientRegistration = () => {
	useTitle('Вход клиента')
	const navigate = useNavigate()

	const isLogged = clientIsLogged()
	
	if (isLogged)
		navigate(path.HOME)

	return (
		<div>
			<ClientRegistrationForm />
		</div>
	)
}

export default ClientRegistration
