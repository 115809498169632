import ClientInfo from '@src/components/ClientInfo/ClientInfo';
import './ClientInfoTile.scss'

const ClientInfoTile = ({onClick}) => {
	return (
		<div className="ClientInfoTile" {...{onClick}}>
			<ClientInfo />
		</div>
	);
}

export default ClientInfoTile