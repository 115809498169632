import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import PhotoBtn from '@src/components/PhotoBtn/PhotoBtn'
import StudioControll from './StudioControll'
import StudioConstructor from './StudioConstructor'
import StudioCamera from './StudioCamera/StudioCamera'
import './Studio.scss'

const Studio = () => {
	const [isPhotoMode, setIsPhotoMode] = useState(false)
	const [modeIsSelected, setModeIsSelected] = useState(false)

	const navigate = useNavigate()

	const backLinkAction = () => {
		if (modeIsSelected || isPhotoMode) {
			setModeIsSelected(false)
			setIsPhotoMode(false)
		} else {
			navigate(-1)
		}
	}

	return (
		<div className="Studio">
			<StudioControll {...{backLinkAction, isPhotoMode, setIsPhotoMode}} />
			<StudioCamera />
			<div className="Studio__panel">
				{isPhotoMode ? 
					<PhotoBtn />
					:
					<StudioConstructor {...{modeIsSelected, setModeIsSelected}} />
				}
			</div>
			
		</div>
	)
}

export default Studio