import './Layout.scss'

const Layout = ({ id, className, children }) => {
	return (
		<div className={`Layout ${className}`} {...{id}}>
			{children}
		</div>
	)
}

export default Layout
