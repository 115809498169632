import './Eye.scss'

const Eye = ({setShow}) => {

	const handleMouseDown = () => {
		setShow(true)
	}

	const handleMouseUp = () => {
		setShow(false)
	}

	const handleTouchStart = () => {
		setShow(true)
	}

	const handleTouchEnd = () => {
		setShow(false)
	}

	return (
		<div 
			className="Eye"
			onMouseDown={handleMouseDown}
			onMouseUp={handleMouseUp}
			onMouseLeave={handleMouseUp}
			onTouchStart={handleTouchStart}
			onTouchEnd={handleTouchEnd}
		></div>
	)
}

export default Eye