import { useNavigate } from 'react-router-dom'
import BackBtn from '@src/components/BackBtn/BackBtn'
import Stars from '@src/components/Stars/Stars'
import './MasterDetailHeader.scss'
import { text } from '@src/helpers/text'

const MasterDetailHeader = ({
	rating,
	name,
	experience,
	description,
	profile_photo
}) => {
	const navigate = useNavigate()

	const backLinkAction = () => {
		navigate(-1)
	}

	return (
		<div className="MasterDetailHeader">
			<div className="MegaHeader__actions">
				<div className="container">
					<BackBtn title="Назад" callback={backLinkAction}/>
				</div>
			</div>
			<div className="container">
				<div className="MasterDetailHeader__card">
					<div className="MasterDetailHeader__list">
						<div>{name || text.notSetCompact}</div>
						<div>{description || text.notSetCompact}</div>
						<div>
							{experience ?
							`Стаж работы ${experience}`
							: text.notSetCompact}
						</div>
						<div><Stars count={rating} large /></div>
					</div>
					<picture className="MasterDetailHeader__avatar">
						<img
							src={profile_photo}
							alt={name}
						/>
					</picture>
				</div>
			</div>
		</div>
	)
}

export default MasterDetailHeader