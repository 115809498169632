import { useRef, useState } from 'react'
import { generateUniqueId } from '@src/utility/generateUniqueId'
import './MegaCheckbox.scss'

const MegaCheckbox = ({checkedState, name}) => {
	const [checked, setChecked] = checkedState
	const [focus, setFocus] = useState(false)

	let className = 'MegaCheckbox'
	const idRef = useRef(generateUniqueId(name))

	const onChange = (e) => setChecked(e.target.checked)
	const onFocus = () => setFocus(true)
	const onBlur = () => setFocus(false)

	if (checked)
		className += ' MegaCheckbox_checked'

	if (focus)
		className += ' MegaCheckbox_focused'

	return (
		<div {...{className}}>
			<input type="checkbox" id={idRef.current} {...{onChange, onFocus, onBlur, name}}/>
		</div>
	)
}

export default MegaCheckbox
