import Input from '@src/components/Input/Input'
import { maxEmailLength, minPassLength } from '@src/config'
import { ON } from '@src/helpers/constants'
import './LoginForm.scss'

const LoginForm = ({ formData, errors, onChange, onSubmit, disabled }) => {
	return (
		<form className="LoginForm" onSubmit={onSubmit}>
			<Input
				label="Email"
				type="email"
				placeholder="example@mail.com"
				name="email"
				value={formData.email}
				maxLength={maxEmailLength}
				errors={errors.email}
				autoComplete="email"
				{...{onChange, disabled}}
			/>
			<Input
				label="Пароль"
				type="password"
				name="password"
				value={formData.password}
				minLength={minPassLength}
				errors={errors.password}
				autoComplete={ON}
				{...{onChange, disabled}}
			/>
		</form>
	)
}

export default LoginForm
