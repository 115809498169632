import { Outlet } from 'react-router-dom'
import Header from '@src/components/Header/Header'
import Layout from '@src/components/Layout/Layout'
import './ClientAuthLayout.scss'

const ClientAuthLayout = () => {

	return (
		<Layout id="ClientAuthLayout" className="auth-form">
			<Header light={true} />
			<Outlet />
		</Layout>
	)
}

export default ClientAuthLayout