import { useRef, useState } from 'react'
import Eye from '@src/components/Eye/Eye'
import { generateUniqueId } from '@src/utility/generateUniqueId'
import ErrorsList from '../ErrorsList/ErrorsList'
import './Input.scss'

const Input = ({
	label,
	type,
	placeholder,
	disabled,
	minLength = 0,
	maxLength = 0,
	commonNote,
	name,
	value,
	onChange,
	errors,
	autoComplete
}) => {
	const [focus, setFocus] = useState(false)
	const [note, setNote] = useState(commonNote || [])
	const [show, setShow] = useState(false)
	const [localValue, setLocalValue] = useState(value)

	const ref = useRef(null)

	const idRef = useRef(generateUniqueId(name))
	let className = 'Input'

	const handleChange = (e) => {
		const newValue = e.target.value

		setLocalValue(newValue)
		
		onChange && onChange(e)

		if (!commonNote) {
			if (newValue.length > 0 && newValue.length < minLength)
				setNote([`Минимальная длина: ${minLength}`])
			else if (maxLength > 0 && newValue.length > 0 && newValue.length > maxLength)
				setNote([`Максимальная длина: ${maxLength}`])
			else
				setNote([])
		}
	}

	const onFocus = () => setFocus(true)
	const onBlur = () => setFocus(false)

	const handleClick = () => {
		if (ref.current)
			ref.current.focus()
	}

	if (disabled)
		className += ' Input_disabled'

	if (focus || (localValue && localValue?.length > 0))
		className += ' Input_focused'

	let noteClass = 'Input__note'

	if (note) noteClass += ' Input__note_show'

	return (
		<div onClick={handleClick} className={className}>
			<div className="Input__field">
				<input
					className="Input__input"
					{...{
						id: idRef.current,
						disabled,
						minLength,
						onBlur,
						onChange: handleChange,
						onFocus,
						placeholder,
						ref,
						type: show ? 'text' : type,
						value: localValue,
						name,
						...(autoComplete ? {...{autoComplete}} : {})
					}}
				/>

				{type === 'password' && localValue && <Eye {...{setShow}} />}

				{label && <label className="Input__label" htmlFor={idRef.current}>{label}</label>}
			</div>
			{note?.length ?
				<div className={noteClass}>
					{note?.length && note.join("\n")}
				</div>
			: ''}
			<ErrorsList {...{errors}} />
		</div>
	)
}

export default Input
