import { text } from '@src/helpers/text'
import { FormatPrice } from '@src/components/FormatPrice/FormatPrice'
import { FormatDuration } from '@src/components/FormatDuration/FormatDuration'
import MegaCheckbox from '@src/components/MegaCheckbox/MegaCheckbox'
import './Rate.scss'

const Rate = ({data, isSelected, onSelect, disabled}) => {
	const checkboxChangeHandler = (event) => event && onSelect()

	let className = 'Rate'

	if (data?.is_hot)
		className += ' Rate_hot'

	if (disabled)
		className += ' Rate_disabled'

	const onClick = () => {
		if (!disabled) onSelect()
	}

	return (
		<section {...{className, onClick}}>
			<div className="Rate__checkbox">
				<MegaCheckbox checkedState={[isSelected, checkboxChangeHandler]} name="rate" />
			</div>
			<div className="Rate__main">
				<header className="Rate__title">
					<FormatDuration value={data?.duration} />
				</header>
				<div className="Rate__price">
					<FormatPrice value={data?.price} />
				</div>
				<div className="Rate__note">
					{data?.name || text.notSetCompact}
				</div>
			</div>
		</section>
	)
}

export default Rate